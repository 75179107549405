import React from "react"
import Layout from "../../components/Layout"
import loadable from "@loadable/component"

// Lazy load the OhmConnect component
const OhmConnect = loadable(() => import("../../components/OhmConnect"), {
  fallback: <div className="loading-fallback">Loading OhmConnect...</div>, // Fallback while loading
})

export default function TrackHero() {
  return (
    <Layout>
      <OhmConnect />
    </Layout>
  )
}
